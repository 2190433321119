import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import BannerSlide from "../components/Banner/BannerSlide"
import H3 from "../utils/H3"
import H6 from "../utils/H6"
import Button from "../utils/Button"
import { motion, AnimatePresence } from "framer-motion"
import Counter from "../components/Counter"
import TestimonialSlider from "../components/TestimonialSlider"
import ReactPlayer from 'react-player/youtube'
import TeacherTile from "../components/TeacherTile"
import WhySection from "../components/Courses/WhySection"
import CurriculumSection from "../components/Courses/CurriculumSection"
import PassionSection from "../components/Courses/PassionSection"
import MeetTeacherSection from "../components/Courses/MeetTeacherSection"
import PriceList from "../components/PriceList"
import PriceListSlider from "../components/slider/swiper"
import { AccordionRow } from "../components/Accordion"
import { plans } from "../data/bharatnatyam-plans";
import Single from "../components/Banner/Single"
import GuruSection from "../components/Courses/GuruSection"
import CounterSection from "../components/CounterSection";
import OrangePerfomanceSections from "../components/Courses/OrangePerfomanceSections"
import FAQSection from "../components/Courses/FAQSection"
import Favicon from "../images/favicon.svg";
import Seo from "../components/seo";
const BharatnatyamPage = () => {
    const bharatnatyamPlans = plans.content;
    return (
        <Layout>
            <Seo title="Learn Bharatanatyam Online" url="https://kaladhwani.com/learn-bharatanatyam-online" description="Free Online Bharatanatyam Trial Class. Kids and Adults. Live Video Classes. Book your demo now. All age groups." image={Favicon} />
            <Single
                image={"/images/bharatnatyam-banner-1.jpg"}
                mobileImage={"/images/bharatnatyam-mobile-banner.jpg"}
                title="BHARATANATYAM"
                subtitle="learn"
            />
            <WhySection
                image="/images/why-bharatnatyam.jpg"
                title="Why Learn Bharatanatyam?"
                description="Bharatanatyam is a classical dance form from Tamil Nadu. It involves all parts of the body to express stories in a rhythmic manner while being one of the most exhilarating forms. <strong>The learning of Bharatanatyam not only involves learning dance, but life skills such as discipline, consistency, expression of thought and confidence also get embedded.</strong> Memory and imagination are stimulated through story-telling and performance techniques, which augment the mind. <strong>Anyone proficient in a classical dance form, can easily learn other dance forms.</strong> Besides, being our cultural wealth, some of the leading bollywood actresses have been successful because of their classical dance background."
            />
            <CurriculumSection
                title="Our 4C Curriculum of BHARATANATYAM"
                video="https://www.youtube.com/embed/6gE9a_KDyfs"
                description="Just like we tune an instrument before playing; in dance, we tune our bodies. Unlike us, most dance schools have little experience in designing a dance curriculum. This is because our curriculum is curated by artists who have experience as arts educators in school spaces. We believe that each individual’s body has its own strength and challenges, and thus a straight jacket solution is not the approach we take. We help the students in getting adept at style specific steps through Conditioning and Command. Creativity and Choreography helps students understand non-verbal cues and spontaneity through individual and peer activities."
            />
            <PassionSection
                keyword="Bharatanatyam"
                link="/book-free-trial"
                image="md:bg-passionBharatnatyam bg-bharatMobilePassion"
            />
            <MeetTeacherSection
                name="Ms. Radhika Kathal"
                role="Bharatanatyam | Hindustani Vocalist"
                image="/images/radhika-teacher.jpg"
                alt="Ms. Radhika Kathal"
                description="Ms. Radhika Kathal is the <strong>founder of Kaladhwani.</strong> She is a senior disciple of <strong>Guru (Padmashri & SNA Awardee) Geeta Chandran.</strong> A post-graduate in Hindustani Music from Faculty of Music (University of Delhi), she currently trains under Dr. Soma Singh. Amongst her accomplishments is her performance at Rashtrapati Bhawan when <strong>US President Mr. Barack Obama visited India.</strong><br/><br/>Radhika is a <strong>graded artist by Doordarshan Kendra,</strong> New Delhi and also a recipient of the <strong>National Scholarship from The Ministry of Culture,</strong> Govt. of India. With more than <strong>750+ stage performances</strong> across India and abroad, Radhika is also an Arts Anchor at Shiv Nadar School, Noida."
                sectionInfo="We believe that performing and teaching arts are two different skill sets, and hence our artists are highly trained and enjoy both the domains. Designed by our Founder Radhika, our one of a kind 4C Curriculum is a result of meticulous planning and hardwork, which ensures that the child expands his/her horizon into the world of dance."
                link="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g"
                callout="Learn with Radhika"
                pdf="/radhika-kathal-bio.pdf"
                heading="MEET THE FOUNDER"
            />
            <div className="flex flex-col justify-center items-center md:bg-radhikaDanceBg bg-leavesBg bg-cover bg-no-repeat h-auto w-full relative">
                <div className="flex flex-col justify-center items-center py-12 w-full relative bg-blackGradient">
                    <H3 color="text-white" uppercase>radhika’s performances</H3>
                    <div className="grid md:grid-cols-3 grid-cols-1 grid-rows-3 md:grid-rows-1 gap-12 w-full lg:px-28 md:px-12 px-4">
                        <div className="w-full rounded-4xl flex flex-col justify-start items-start">
                            <ReactPlayer controls={true} url='https://www.youtube.com/watch?v=o6wBe_EvMBg' className="react-player" width="100%" height="219px" />
                            <p className="text-white text-lg mt-4 font-bold">Sumasayika Varnam | Raza Foundation</p>
                        </div>
                        <div className="w-full rounded-4xl flex flex-col justify-start items-start">
                            <ReactPlayer controls={true} url='https://www.youtube.com/watch?v=n_iOsw5bUig' className="react-player" width="100%" height="219px" />
                            <p className="text-white text-lg mt-4 font-bold">Raageshri Tillana | Pongal Dance Festival</p>
                        </div>
                        <div className="w-full rounded-4xl flex flex-col justify-start items-start">
                            <ReactPlayer controls={true} url='https://www.youtube.com/watch?v=uCfQGaeIdAk' className="react-player" width="100%" height="219px" />
                            <p className="text-white text-lg mt-4 font-bold">Kaladhwani Collective | Gaja Mahotsav</p>
                        </div>
                    </div>
                    <a href="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g" target="_blank" rel="noreferrer" className="text-white text-sm font-bold underline ml-auto mr-20 mt-8 transition-all duration-300 ease transform hover:scale-105">See More &gt;&gt;</a>
                </div>
            </div>
            <GuruSection
                name="Smt. Geeta Chandran"
                role="Padmashri & SNA Awardee"
                image="/images/geeta-chandran.jpg"
                alt="Smt. Geeta Chandran"
                description="<i>My Guru Geeta Chandran, as I fondly call her Akka, has been instrumental in shaping me as a dancer.</i><br/><br/>Geeta Akka began learning Bharatanatyam from the tender age of 5 years under the tutelage of Smt. Swarna Saraswathy, who hailed from the traditional Thanjavoor devadasi Parampara and continued learning diverse aspects of the classical dance from a galaxy of eminent Gurus.<br/><br/>Founder-President of Natya-Vriksha, she is also the Artistic Director of  Natya Vriksha Dance Company. Akka wears multiple hats and is celebrated not only for her deep and composite understanding of the art of Bharatanatyam, but also for her Carnatic music (she is a trained and accomplished vocalist), her work in television, theater, choreography, dance education, dance activism and dance-issue journalism.<br/><br/>An A-Top graded dancer at Doordarshan and at the Indian Council for <br/><a href='#' target='_blank' class='font-bold mb-8 text-mustard'>Read more...</a>"
                link="#"
                callout="Radhika’s GURU"
            />
            <PriceList plans={bharatnatyamPlans} dance="bharatnatyam" curriculum="/BHARATANATYAM CURRICULUM.pdf"></PriceList>
            <PriceListSlider plans={bharatnatyamPlans} dance="bharatnatyam" curriculum="/BHARATANATYAM CURRICULUM.pdf" />
            {/* counters section */}
            <CounterSection />
            <div className="bg-white py-12 flex justify-center flex-col items-center">
                <H3 color="text-black" uppercase>Hear from our community</H3>
                <TestimonialSlider />
            </div>

            <OrangePerfomanceSections></OrangePerfomanceSections>
            <FAQSection></FAQSection>
        </Layout >
    )
}

export default BharatnatyamPage





